// Lib
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Ui
import GlobalStyle from '../style/globalStyles';
import { HomeBody } from '../pages/home.jsx';
import  { FaqPage }  from '../pages/faq/faq.jsx';
import { CguBody } from '../pages/cgu/cgu.jsx';
import { Page404Body } from '../pages/404/404.jsx';
import Navbar from '../components/NavBar/Navbar';
import Footer from '../components/Footer/Footer';
import ScrollToTop from 'react-scroll-to-top';

/**
 * RootRouter component
 * @class RootRouter
 * @reactProps {langKey} langKey - langue ask by the user 'fr' or 'en'
 * @desc Entry point of the main router to dispatch route with reactRouter
 * @extends {React.Component}
 * @public
 * @version 1.0
 * @since 1.0
 */
class RootRouter extends React.Component {
    /**
    * @method render - lifecycle method for render React Element (jsx)
    * @desc this render handle every route to correspond URL with every mounting component
    * @return {object} - React Element
    * @private
    * @version 1.0
    * @since 1.0
    */
    render() {
        return (
            <Router>
                <GlobalStyle />
                <Navbar />
                <Switch>
                    <Route exact path="/" component={ HomeBody } />
                    <Route exact path="/faq" component={ FaqPage } />
                    <Route exact path="/cgu" component={ CguBody } />
                    <Route path="*" component={ Page404Body } />
                </Switch>
                <Footer />
                <ScrollToTop smooth top={ 750 } className="stop-display" />
            </Router>
        );
    }
}

export { RootRouter};
