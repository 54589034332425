import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import CommonFr from './translations/fr/common.json';
import CommonEn from './translations/en/common.json';

i18n
    .use(initReactI18next)
    .init({
        lng: navigator.language || navigator.userLanguage,
        useCookie: false,
        load: 'languageOnly',
        whitelist: ['fr', 'en'],
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                common: CommonEn,
            },
            fr: {
                common: CommonFr,
            },
        },
    });


export default i18n;
