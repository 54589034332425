/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Container, Section } from '../../style/globalStyles';
import {
    FeatureText,
    FeatureTitle,
    FeatureWrapper,
    FeatureColumn,
    FeatureImageWrapper,
    FeatureName,
    FeatureTextWrapper,
} from './FeaturesStyles';
import { featuresData } from './data';
import { useTranslation } from 'react-i18next';

const Features = () => {
    const initial = {
        y: 40,
        opacity: 0,
    };
    const animate = {
        y: 0,
        opacity: 1,
    };

    const openWindow = (url) => {
        window.open(url);
    };

    const { t } = useTranslation('common');

    return (
        <Section smPadding="50px 10px" position="relative" inverse id="about">
            <Container>
                <FeatureTextWrapper onClick={ () => openWindow('https://societe.sacem.fr/') }>
                    <FeatureTitle style={ { cursor: 'pointer'} }>
                        { t('desc-sacem') }
                    </FeatureTitle>
                </FeatureTextWrapper>
                <FeatureWrapper style={ { cursor: 'pointer'} } onClick={ () => openWindow('https://societe.sacem.fr/') }>
                    {featuresData.map((el, index) => (
                        <FeatureColumn
                            initial={ initial }
                            animate={ animate }
                            transition={ { duration: 0.5 + index * 0.1 } }
                            key={ index }
                        >
                            <FeatureImageWrapper className={ el.imgClass }>
                                {el.icon}
                            </FeatureImageWrapper>
                            <FeatureName>
                                {t(`${el.name}`)}
                            </FeatureName>
                            <FeatureText>
                                {t(`${el.description}`)}
                            </FeatureText>
                        </FeatureColumn>
                    ))}
                </FeatureWrapper>
            </Container>
        </Section>
    );
};

export default Features;
