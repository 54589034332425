/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    FooterLogo,
    SocialIcon,
    FooterRights,
    FooterSocialIcon,
    FooterWrapper,
    FooterAddress,
    FooterColumn,
    FooterGrid,
} from './FooterStyles';
import { Row, Section } from '../../style/globalStyles';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
    {
        name: 'Facebook',
        icon: iconStyle(FaFacebook),
        url: 'https://www.facebook.com/LaSacem/',
    },
    {
        name: 'Instagram',
        icon: iconStyle(FaInstagram),
        url: 'https://instagram.com/sacem',
    },
    {
        name: 'YouTube',
        icon: iconStyle(FaYoutube),
        url: 'https://www.youtube.com/sacem',
    },
    {
        name: 'Twitter',
        icon: iconStyle(FaTwitter),
        url: 'https://twitter.com/sacem',
    },
    {
        name: 'LinkedIn',
        icon: iconStyle(FaLinkedin),
        url: 'https://www.linkedin.com/company/sacem',
    },
];

export const footerData = [
    {
        title: 'footer-1',
        links: [
            {
                name: 'footer-1-faq',
                url: '/faq',
                isBlank: false,
            },
            {
                name: 'footer-1-contact',
                url: 'https://societe.sacem.fr/innovation/contact',
                isBlank: true,
            },
            {
                name: 'footer-1-sacem',
                url: 'https://www.sacem.fr',
                isBlank: true,
            },
        ],
    },
    {
        title: 'footer-2',
        links: [
            {
                name: 'footer-2-conf',
                url: 'https://www.sacem.fr/politique-confidentialite',
                isBlank: true,

            },
            {
                name: 'footer-2-legal',
                url: 'https://www.sacem.fr/mentions-legales',
                isBlank: true,

            },
            {
                name: 'footer-2-cgu',
                url: '/cgu',
                isBlank: false,

            },
        ],
    },
];

function Footer() {
    const { t } = useTranslation('common');

    const openWindow = (url) => {
        window.open(url);
    };

    return (
        <Section padding="4rem 0 2rem 0">
            <FooterWrapper>
                <FooterGrid justify="space-between">
                    <FooterColumn id="footerLogo">
                        <FooterLogo to={ { pathname: 'https://www.sacem.fr' } } target="_blank">
                            <SocialIcon src="./assets/logo-sacem.png" />
                        </FooterLogo>
                        <FooterAddress>
                            {'225 avenue Charles de Gaulle'}
                            <br />
                            {'92528 Neuilly sur Seine Cedex'}
                            <br />
                            {'01 47 15 47 15'}
                        </FooterAddress>
                        <Row align="center" margin="auto  0 0 0" gap="1rem">
                            {footerSocialData.map((social, index) => (
                                <FooterSocialIcon
                                    key={ index }
                                    href={ social.url }
                                    target="_blank"
                                    aria-label={ social.name }
                                >
                                    {social.icon}
                                </FooterSocialIcon>
                            ))}
                        </Row>
                    </FooterColumn>
                    {footerData.map((footerItem, index) => (
                        <FooterLinkItems key={ index }>
                            <FooterLinkTitle>
                                {t(`${footerItem.title}`)}
                            </FooterLinkTitle>
                            {footerItem.links.map((link, linkIndex) => (
                                <FooterLink key={ linkIndex } to={ { pathname: link.url } } target={ link?.isBlank ? '_blank' : '' }>
                                    {t(`${link.name}`)}
                                </FooterLink>
                            ))}
                        </FooterLinkItems>
                    ))}
                </FooterGrid>
                <FooterRights style={ { cursor: 'pointer'} } onClick={ () => openWindow('https://societe.sacem.fr/innovation') }>
                    {'Sacem Lab 2022 with ❤️'}
                </FooterRights>
            </FooterWrapper>
        </Section>
    );
}

export default Footer;
