/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import {
    ContentRow,
    TextWrapper,
    Heading,
    ContentColumn,
    Container4,
    Section4,
    Subtitle,
    ContentButton,
} from './ContentStyles.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const data = {
    reverse: true,
    inverse: false,
};

export const Page404Body = () => {
    const {
        inverse,
        reverse } = data;
    const initial = { opacity: 0, y: 30 };
    const animation = useAnimation();

    const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
    }, [inView, animation]);

    const { t } = useTranslation('common');
    const history = useHistory();
    return (
        <Section4 inverse={ inverse } ref={ ref }>
            <Container4>
                <ContentRow reverse={ reverse }>
                    <ContentColumn>
                        <TextWrapper>
                            <Heading
                                initial={ initial }
                                transition={ { delay: 0.5, duration: 0.6 } }
                                animate={ animation }
                                inverse={ inverse }
                            >
                                {t('404-head')}
                            </Heading>
                            <Subtitle>
                                {t('404-sub')}
                            </Subtitle>
                        </TextWrapper>
                        <ContentButton
                            initial={ initial }
                            transition={ { delay: 0.1, duration: 0.3 } }
                            animate={ animation }
                            inverse={ inverse }
                            onClick={ () => history.push('/') }
                        >
                            {t('404-but')}
                        </ContentButton>
                    </ContentColumn>
                </ContentRow>
            </Container4>
        </Section4>
    );
};


