import React from 'react';

import { BsFillShieldLockFill } from 'react-icons/bs';
import { RiPieChart2Fill } from 'react-icons/ri';
import { HiSpeakerphone } from 'react-icons/hi';
import { FaLightbulb } from 'react-icons/fa';
const iconStyle = (Icon) => <Icon size="3rem" />;

export const featuresData = [
    {
        name: 'desc-sacem-1-title',
        description: 'desc-sacem-1-subtitle',
        icon: iconStyle(RiPieChart2Fill),
        imgClass: 'one',
    },
    {
        name: 'desc-sacem-2-title',
        description: 'desc-sacem-2-subtitle',
        icon: iconStyle(HiSpeakerphone),
        imgClass: 'two',
    },
    {
        name: 'desc-sacem-3-title',
        description: 'desc-sacem-3-subtitle',
        icon: iconStyle(BsFillShieldLockFill),
        imgClass: 'three',
    },
    {
        name: 'desc-sacem-4-title',
        description: 'desc-sacem-4-subtitle',
        icon: iconStyle(FaLightbulb),
        imgClass: 'three',
    },
];
