/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-max-depth */
import React, { useEffect } from 'react';
import { Container, Section } from '../../style/globalStyles';
import {
    ContentRow,
    TextWrapper,
    TopLine,
    Heading,
    ContentButton,
    Subtitle,
    ImgWrapper,
    Img,
    ContentColumn,
} from './ContentStyles.js';
import { data } from './data.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const DescContent = () => {

    const discordUrl = 'https://discord.gg/uMJFHuaQ43';
    const gpsUrl = 'https://societe.sacem.fr/actualites';

    const {
        img,
        alt,
        inverse,
        reverse,
    } = data;
    const initial = { opacity: 0, y: 50 };
    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
    }, [inView, animation]);

    const openWindow = (url) => {
        window.open(url);
    };

    const { t } = useTranslation('common');

    return (
        <Section inverse={ inverse } ref={ ref } id="a">
            <Container>
                <ContentRow reverse={ reverse }>
                    <ContentColumn>
                        <TextWrapper>
                            <TopLine
                                initial={ initial }
                                transition={ { delay: 0.1, duration: 0.3 } }
                                animate={ animation }
                            >
                                {t('top-line-desc')}
                            </TopLine>
                            <Heading
                                initial={ initial }
                                transition={ { delay: 0.1, duration: 0.3 } }
                                animate={ animation }
                                inverse={ inverse }
                                onClick={ () => openWindow(gpsUrl) }
                                style={ { cursor: 'pointer'} }
                            >
                                {t('headline-desc')}
                            </Heading>
                            <Subtitle
                                initial={ initial }
                                transition={ { delay: 0.1, duration: 0.3 } }
                                animate={ animation }
                                inverse={ inverse }
                            >
                                {t('desc-1-desc')}
                                <br />
                                <br />
                                {t('desc-2-desc')}
                                <br />
                                <br />
                                {t('desc-3-desc')}
                            </Subtitle>
                            <ContentButton
                                initial={ initial }
                                transition={ { delay: 0.1, duration: 0.3 } }
                                animate={ animation }
                                inverse={ inverse }
                                onClick={ () => openWindow(discordUrl) }
                            >
                                {t('button-discord')}
                            </ContentButton>
                        </TextWrapper>
                    </ContentColumn>
                    <ContentColumn
                        initial={ initial }
                        transition={ { delay: 0.2, duration: 0.4 } }
                        animate={ animation }
                    >
                        <ImgWrapper>
                            <Img
                                src={ img }
                                alt={ alt }
                                whileHover={ { rotate: 2, scale: 1.02 } }
                                transition={ { duration: 0.3 } }
                                style={ { cursor: 'pointer'} }
                                onClick={ () => openWindow(gpsUrl) }
                            />
                        </ImgWrapper>
                    </ContentColumn>
                </ContentRow>
            </Container>
        </Section>
    );
};

export default DescContent;
