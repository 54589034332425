/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { CgMenuRight } from 'react-icons/cg';
import { IconContext } from 'react-icons';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    NavIcon,
    MobileIcon,
    NavMenu,
    NavButton,
    NavItem,
} from './NavbarStyles.js';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const data = [
    {
        to: '/faq',
        text: 'FAQ',
        id: 'f',
    },
    {
        to: 'https://discord.gg/uMJFHuaQ43',
        text: 'DISCORD',
        id: 'd',
    },
    {
        to: 'https://twitter.com/sacem',
        text: 'TWITTER',
        id: 't',
    },
];

const Navbar = () => {
    const [show, setShow] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const handleClick = () => {
        setShow(!show);
    };

    const scrollTo = (id) => {
        const element = document.getElementById(id);

        element.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const closeMobileMenu = (to, id) => {
        setShow(false);
        if (id === 'a' && location.pathname === '/') {
            scrollTo(id);
        } else if (id === 'd' || id === 't') {
            window.open(to);
        } else {
            history.push(to);
            if (id === 'a') {
                setTimeout(() => {
                    scrollTo(id);
                });
            }
        }
    };

    const handleLogoClose = () => {
        show && setShow(false);
        history.push('/');
    };

    const { t } = useTranslation('common');


    return (
        <IconContext.Provider value={ { color: '#fff' } }>
            <Nav>
                <NavbarContainer>
                    <NavLogo onClick={ () => handleLogoClose() }>
                        <NavIcon src="./assets/gps.png" alt="logo" />
                    </NavLogo>
                    <MobileIcon onClick={ handleClick }>
                        {show ? <FaTimes /> : <CgMenuRight />}
                    </MobileIcon>
                    <NavMenu show={ show }>
                        <NavItem key="42">
                            <NavButton onClick={ () => closeMobileMenu('/', 'a') }>
                                {t('about')}
                            </NavButton>
                        </NavItem>
                        {data.map((el, index) => (
                            <NavItem key={ index }>
                                <NavButton onClick={ () => closeMobileMenu(el.to, el.id) }>
                                    {el.text}
                                </NavButton>
                            </NavItem>
                        ))}
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    );
};

export default Navbar;
