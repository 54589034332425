import styled from 'styled-components';
import { Button } from '../../style/globalStyles';

export const HeroSection = styled.section`
	height: 100vh;
	background-position: center;
	background-size: cover;
	padding-top: clamp(70px, 25vh, 220px);
	box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
`;

export const HeroVideo = styled.video`
	object-fit: cover;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
	top: 0;
	position: absolute;
	z-index: -1;
`;

export const HeroText = styled.p`
	margin-Top: 10px;
	font-size: clamp(0.9rem, 1.5vw, 1.3rem);
	line-height: 24px;
	text-align: center;
	letter-spacing: 2px;
	color: #fff;
`;

export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 0.5rem;
	margin-top: 2%;
`;

export const ArrowWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 46%;
    color: white;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const HeroButtonGo = styled(Button)`
	border-radius: 18px;
	background: none;
	white-space: nowrap;
	padding: 10px 20px;
	font-size: 16px;
	color: #fff;
	outline: none;
	border: 2px solid #fff;
	cursor: pointer;
	overflow: hidden;
	position: relative;

	&:before {
		background: #fff;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.6s ease;
		width: 100%;
		height: 500%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:hover:before {
		height: 0%;
	}

	&:hover {
		color: white;
	}
`;


export const HeroButton = styled(Button)`
	color: black;
	width: 30%;
    
	@media screen and (max-width: 768px) {
		width: 80%;
	}

	&:before {
		background: #fff;
		height: 1500%;
	}

	&:hover:before {
		height: 0%;
	}

	&:hover {
		color: white;
	}
`;
