/* eslint-disable react/jsx-max-depth */
import React, { useEffect } from 'react';
import {
    ContentRow,
    TextWrapper,
    Heading,
    ContentColumn,
    ContainerCgu,
    SectionCgu,
    Subtitle,
    HeadText,
} from './ContentStyles.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const data = {
    reverse: true,
    inverse: false,
};

export const CguBody = () => {
    const {
        inverse,
        reverse } = data;
    const initial = { opacity: 0, y: 30 };
    const animation = useAnimation();

    const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
    }, [inView, animation]);

    const { t } = useTranslation('common');
    const CguRows = t('cgu', {returnObjects: true});

    return (
        <SectionCgu inverse={ inverse } ref={ ref }>
            <ContainerCgu>
                <ContentRow reverse={ reverse }>
                    <ContentColumn>
                        <TextWrapper>
                            <Heading
                                initial={ initial }
                                transition={ { delay: 0.5, duration: 0.6 } }
                                animate={ animation }
                                inverse={ inverse }
                            >
                                {CguRows.headTitle}
                            </Heading>
                            <HeadText>
                                {CguRows.section1.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section1.text1}
                                <b>
                                    {CguRows.section1.text2bold}
                                </b>
                                {CguRows.section1.text3}
                                <b>
                                    {CguRows.section1.text4bold}
                                </b>
                                {CguRows.section1.text5}
                                <br />
                                <br />
                                {CguRows.section1.text6br}

                            </Subtitle>
                            <HeadText>
                                {CguRows.section2.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section2.text1}
                                <br />
                                <br />
                                {CguRows.section2.text2}
                                <br />
                                <br />
                                {CguRows.section2.text3}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section3.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section3.text1}
                                <br />
                                <br />
                                {CguRows.section3.text2}
                                <br />
                                <br />
                                {CguRows.section3.text3}
                                <a href="https://nft.sacem.fr" target="_blank" rel="noreferrer" style={ {color: 'white'} }>
                                    {'https://nft.sacem.fr'}
                                </a>
                                {CguRows.section3.text3a}
                                <a href="https://my.avatarz.com/SACEM/claim" target="_blank" rel="noreferrer" style={ {color: 'white'} }>
                                    {'https://my.avatarz.com/SACEM/claim'}
                                </a>
                                {CguRows.section3.text3b}
                                <br  />
                                {CguRows.section3.text3c}
                                <ul style={ {marginLeft: '5%'} }>
                                    <li>
                                        {CguRows.section3.text3e}
                                    </li>
                                    <li>
                                        {CguRows.section3.text3d}
                                    </li>
                                </ul>
                                <br />
                                {CguRows.section3.text3f}
                                <br />
                                <br />
                                {CguRows.section3.text3g}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section4.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section4.text1}
                                <br />
                                {CguRows.section4.text2}
                                <ul style={ {marginLeft: '5%'} }>
                                    <li>
                                        {CguRows.section4.text3}
                                    </li>
                                    <li>
                                        {CguRows.section4.text3a}
                                    </li>
                                    <li>
                                        {CguRows.section4.text3b}
                                    </li>
                                    <li>
                                        {CguRows.section4.text3c}
                                    </li>
                                </ul>
                                <br />
                                {CguRows.section4.text4}
                                <br />
                                {CguRows.section4.text5}
                                <br />
                                <br />
                                {CguRows.section4.text6}
                                <a href="https://tropee.com" target="_blank" rel="noreferrer" style={ {color: 'white'} }>
                                    {'https://tropee.com'}
                                </a>
                                {CguRows.section4.text6a}
                                <a href="https://nft.sacem.fr" target="_blank" rel="noreferrer" style={ {color: 'white'} }>
                                    {'https://nft.sacem.fr'}
                                </a>
                                {CguRows.section4.text6b}
                                <ul style={ {marginLeft: '5%'} }>
                                    <li>
                                        {CguRows.section4.text6c}
                                    </li>
                                    <li>
                                        {CguRows.section4.text6d}
                                    </li>
                                </ul>
                                <br />
                                {CguRows.section4.text7}
                                <br />
                                {CguRows.section4.text8}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section5.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section5.text1}
                                <br />
                                <br />
                                {CguRows.section5.text2}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section6.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section6.text1}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section7.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section7.text1}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section8.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section8.text1}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section9.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section9.text1}
                                <br />
                                <br />
                                {CguRows.section9.text2}
                                <br />
                                <br />
                                {CguRows.section9.text3}
                                <br />
                                <br />
                                {CguRows.section9.text4}
                                <br />
                                <br />
                                {CguRows.section9.text5}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section10.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section10.text1}
                                <br />
                                <br />
                                {CguRows.section10.text2}
                                <br />
                                <br />
                                {CguRows.section10.text3}
                            </Subtitle>
                            <HeadText>
                                {CguRows.section11.title}
                            </HeadText>
                            <Subtitle>
                                {CguRows.section11.text1}
                                <br />
                                <br />
                                {CguRows.section11.text2}
                                <ul style={ {marginLeft: '5%'} }>
                                    <li>
                                        {CguRows.section11.text2a}
                                    </li>
                                    <li>
                                        {CguRows.section11.text2b}
                                    </li>
                                    <li>
                                        {CguRows.section11.text2c}
                                    </li>
                                    <li>
                                        {CguRows.section11.text2d}
                                    </li>
                                    <li>
                                        {CguRows.section11.text2e}
                                    </li>
                                </ul>
                                <br />
                                {CguRows.section11.text3}
                                <a href="https://www.sacem.fr/politique-confidentialite" target="_blank" rel="noreferrer" style={ {color: 'white'} }>
                                    {CguRows.section11.text3a}
                                </a>
                                {CguRows.section11.text3b}
                                <a href="https://sacem.fr" target="_blank" rel="noreferrer" style={ {color: 'white'} }>
                                    {'https://sacem.fr'}
                                </a>
                            </Subtitle>
                        </TextWrapper>
                    </ContentColumn>
                </ContentRow>
            </ContainerCgu>
        </SectionCgu>
    );
};


